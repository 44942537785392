import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";
import Card from "@mui/material/Card";
import {Link} from "react-router-dom";


function Workshops() {

    const page_text = {
        fontSize: "16px",
        verticalAlign: "top",
        
    }

    const page_left = {
        ...page_text,
        marginRight: "1rem"
        
    }

    const page_right = {
        ...page_text,
        marginLeft: "1rem"
    }

    const page_body = {
        textAlign: "left",
        textIndent: "2rem"
    }

    const author_text = {
        fontSize: "12px",
        verticalAlign: "top",
        textAlign: "center",
        textIndent: "2rem"
    }

    const announcement_text = {
        fontSize: "14px",
        verticalAlign: "top",
        textAlign: "center",
        textIndent: "2rem"
    }

    return (
        <>
            <MKBox
                        minHeight="30vh"
                        width="100%"
                        sx={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                        }}
                ></MKBox>

                <Card id="other-card"
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 8 },
                        mt: `${180}px`,
                        mb: 4,
                    }}
                >
                    <h3>Workshops</h3>


                    <p style={{ fontSize: "16px", marginTop: "2px" }}>
                        Workshops are by invitation only. 
                        To be considered for an invited talk, please contact the workshop chairs or the general chair.
                    </p>

{/*                     <p style={{ fontSize: "16px", marginTop: "4px" }}>
                        Workshop announcements will be available soon. Please check back later.
                    </p> */}
                    <table style={{ textAlign: "center", borderSpacing: "2rem" }}>
                        <tr>

                            <td style={page_left}>
                                <h4>12th Workshop on Computational Advances for Next Generation Sequencing<br />
                                    {/* (<a href="./main/CANGS2021.pdf">CLICK HERE</a> to download the full workshop announcement.) */}
                                </h4>
                                <p style={announcement_text}>
                                <a href="/resources/cangs_2023_workshop.pdf" target="_blank" rel="noopener noreferrer">
                                    <b>Workshop Announcement</b>
                                </a>
                                </p>

                                <p style={author_text}>
                                    <b>Ion Mandoiu</b> (University of Connecticut, <a href="mailto:ion@engr.uconn.edu">ion@engr.uconn.edu</a>)
                                </p>
                                <p style={author_text}>
                                    <b>Pavel Skums</b> (University of Connecticut, <a href="mailto:pavel.skums@uconn.edu">pavel.skums@uconn.edu</a>)
                                </p>
                                <p style={author_text}>
                                    <b>Alex Zelikovsky</b> (Georgia State University, <a href="mailto:alexz@cs.gsu.edu">alexz@cs.gsu.edu</a>)
                                </p>

                                <p style={page_body}>
                                    Massively parallel DNA and RNA sequencing have become widely available, 
                                    reducing the cost by several orders of magnitude and placing the capacity to generate gigabases 
                                    to terabases of sequence data into the hands of individual investigators. 
                                    These next-generation technologies have the potential to dramatically accelerate biological and 
                                    biomedical research by enabling the comprehensive analysis of genomes and transcriptomes to become inexpensive, 
                                    routine and widespread. The exploding volume of data has spurred the development of 
                                    novel algorithmic approaches for primary analyses of sequence data in such areas as error correction, 
                                    de novo genome assembly, novel transcript discovery, virus quasispecies assembly, etc. 
                                    This workshop will bring together specialists to discuss the various mathematical and computational 
                                    challenges presented by next-generation sequencing technologies.</p>
                            </td>

                            <td style={page_left}>
                                <h4>5th Workshop on Computational Advances for Single-Cell Omics Data Analysis<br />
                                    {/* (<a href="./main/CASCODA2021.pdf">CLICK HERE</a> to download the full workshop announcement.) */}
                                </h4>
                                <p style={announcement_text}>
                                <a href="/resources/cascoda_2023_workshop.pdf" target="_blank" rel="noopener noreferrer">
                                    <b>Workshop Announcement</b>
                                </a>
                                </p>

                                <p style={author_text}>
                                    <b>Alex Zelikovsky</b> (Georgia State University, <a href="mailto:alexz@gsu.edu"> alexz@gsu.edu</a>)
                                </p>
                                <p style={author_text}>
                                    <b>Marmar Moussa</b> (University of Oklahoma, <a href="mailto:marmar.moussa@ou.edu"> marmar.moussa@ou.edu</a>)
                                </p>
                                <p style={author_text}>
                                    <b>Murray Patterson</b> (Georgia State University, <a href="mailto:mpatterson30@gsu.edu"> mpatterson30@gsu.edu</a>)
                                </p>

                                <p style={page_body}>
                                    Recent technological advances have enabled high-throughput profiling of genomes, 
                                    transcriptomes, epigenomes, and proteomes at single cell resolution.  
                                    These revolutionary single-cell-omics technologies promise to bring unprecedented insights 
                                    into tissue heterogeneity and unveil subtle regulatory processes that are undetectable by 
                                    bulk sample analysis.  However, fully realizing their potential requires the development of 
                                    novel computational and statistical analysis methods capable of handling the massive data sizes 
                                    and significant levels of technical and biological noise. The goal of this workshop is to bring 
                                    together bioinformaticians, biologists, computer/data scientists, and statisticians to discuss 
                                    the latest developments in computing infrastructure, mathematical and statistical modeling, 
                                    algorithms, and visualization methods for single-cell-omics data.</p>
                            </td>

                        </ tr>

                        <tr>

                            <td style={page_right}>
                                <h4>11th Workshop on Computational Advances in Molecular Epidemiology<br />
                                    {/* (<a href="./main/CAME2021.pdf">CLICK HERE</a> to download the full workshop announcement.) */}
                                </h4>
                                <p style={announcement_text}>
                                <a href="/resources/came_2023_workshop.pdf" target="_blank" rel="noopener noreferrer">
                                    <b>Workshop Announcement</b>
                                </a>
                                </p>

                                <p style={author_text}>
                                    <b>Mukul S. Bansal</b> (University of Connecticut, <a href="mailto:mukul.bansal@uconn.edu">mukul.bansal@uconn.edu</a>)
                                </p>
                                <p style={author_text}>
                                    <b>Yury Khudyakov</b> (Centers for Disease Control and Prevention, <a href="mailto:yek0@cdc.gov">yek0@cdc.gov</a>)
                                </p>
                                <p style={author_text}>
                                    <b>Pavel Skums</b> (University of Connecticut, <a href="mailto:pavel.skums@uconn.edu">pavel.skums@uconn.edu</a>)
                                </p>

                                <p style={page_body}>
                                    Molecular epidemiology is an integrative scientific discipline that relates 
                                    the molecular underpinnings of biological processes and environmental risk factors to the etiology, 
                                    spread, and prevention of disease in human populations. Over the years, molecular epidemiology has 
                                    become extensively fused with mathematical and computational science and has benefited immensely 
                                    from this tight association. This workshop serves as a forum to review and discuss the latest 
                                    advances in the application of mathematical and computational approaches 
                                    to molecular epidemiology.</p>
                            </td>

                            <td style={page_right}>
                                <h4>2nd Workshop on Advances in Systems Immunology<br />
                                    {/* (<a href="./main/ASI2021.pdf">CLICK HERE</a> to download the full workshop announcement.) */}
                                </h4>

                                <p style={announcement_text}>
                                    <a href="/resources/asi_2023_workshop.pdf" target="_blank" rel="noopener noreferrer">
                                        <b>Workshop Announcement</b>
                                    </a>
                                </p>

                                <p style={author_text}>
                                    <b>Marmar Moussa</b> (School of Computer Science, University of Oklahoma, <a href="mailto:marmar.moussa@ou.edu"> marmar.moussa@ou.edu</a>)
                                </p>
                                <p style={author_text}>
                                    <b>Ion Mandoiu</b> (School of Engineering, University of Connecticut, <a href="mailto:ion@engr.uconn.edu"> ion@engr.uconn.edu</a>)
                                </p>
                                <p style={author_text}>
                                    <b>Wei Chen</b> (Stephenson School of Biomedical Engineering, University of Oklahoma, <a href="mailto:ion@wei-r-chen@ou.edu"> wei-r-chen@ou.edu</a>)
                                </p>
                                
                                <p style={page_body}>
                                    The immune system is one of the most complex biological systems in mammals, 
                                    comprising dozens of cell types that dynamically modulate their internal molecular states 
                                    and engage in complex intercellular interactions via hundreds of immune receptors and 
                                    signaling molecules across multiple anatomic locations. 
                                    Recent advances in high-throughput profiling technologies are driving a shift towards 
                                    context driven integrative computational models and algorithms to study immune system responses 
                                    in both health and disease. The goal of the ASI workshop is to bring together 
                                    a diverse group of researchers - including bioinformaticians, biotechnologists, clinicians, 
                                    computer and data scientists, immunologists, and statisticians - 
                                    to discuss the latest computational advances in systems immunology and foster the 
                                    development of novel multidisciplinary approaches in this field.</p>
                            </td>

                        </ tr>
                    </table>
                </Card>
        </>
    )
}


export default Workshops;