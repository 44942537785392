import Card from "@mui/material/Card";
import okImage from "assets/media/image-5c9df555-e39a-4e8a-932f-4b0d83d562ac.png";
import MKTypography from "components/MKTypography";
import Navbar from "navbar.js";


function DeadlineDatesBar(prop) {
  return (
    <p style={{ fontSize: "1vw", textAlign: "center" }}>
      <b>Paper Submission</b>: <i>October 21, 2023</i>&emsp;&emsp;
      <b>Acceptance Notification</b>: <i>November 8, 2023</i>&emsp;&emsp;
      {/*
      <b>Final Paper</b>: <i>November 15, 2023</i>&emsp;&emsp;
      */}
      <b>Early Registration</b>: <i>November 15, 2023</i>
    </p>
  )
}

function MainCard(prop) {
  return (
    <Card
      sx={{
        p: 2,
        mx: { xs: 2, lg: 8 },
        mt: prop.margin_top,
        mb: prop.margin_bottom,
        backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.4),
        backdropFilter: "saturate(200%) blur(30px)",
        boxShadow: ({ boxShadows: { xxl } }) => xxl,
        overflow: "hidden"
      }}
      /*
      style={{
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
      }}
      */
    >
      <div style={{ display: "flex", justifyContent: "space-between", maxHeight: "60px" }}>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <MKTypography variant="h1" fontWeight="bold" fontSize="3vw">
            ICCABS 2023
          </MKTypography>&emsp;
          <MKTypography variant="h5" fontSize="1.5vw">
            December 11<sup>th</sup> to 13<sup>th</sup> in Oklahoma
          </MKTypography>
        </div>
        <a href="https://www.ou.edu/" target="_blank" rel="noopener noreferrer" style={{ }}>
          <img src={okImage} alt="ok" style={{ maxWidth: "60px", width: "5vw", verticalAlign: "bottom" }} /></a>
      </div>
      <Navbar />
      {/*Show <DeadlineDatesBar /> if show_dates = True*/}
      {prop.show_dates && <DeadlineDatesBar />}
    </Card>
  )
}

export default MainCard;