import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";
import PropTypes from 'prop-types';

// Opens link in new tab and prevents tab from being hijacked
function SecureLinkNT(props) {
    const { url, text } = props;
    return(
        <a href={url} target="_blank" rel="noopener noreferrer">{text}</a>
    );
}

SecureLinkNT.propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  };

function Committees() {
    const ulstyle = {
        fontSize: "16px",
        marginLeft: "100px",
        marginBottom: "8px"
    }

    return (
        <>
            <MKBox
                    minHeight="30vh"
                    width="100%"
                    sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                    }}
            ></MKBox>

            <Card id="other-card"
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 8 },
                    mt: `${180}px`,
                    mb: 4,
                }}
            >
                <h5>General Chair</h5>
                <ul style={ulstyle}>
                    <li><SecureLinkNT url="https://www.ou.edu/coe/cs/people/moussa" text="Marmar Moussa" /> - University of Oklahoma (Chair)</li>
                </ul>
                <h5>Steering Committee</h5>
                <ul style={ulstyle}>
                    <li><SecureLinkNT url="https://raj.cse.uconn.edu/" text="Sanguthevar Rajasekaran" /> - University of Connecticut (Chair)</li>
                    <li><SecureLinkNT url="https://www.cc.gatech.edu/~saluru/" text="Srinivas Aluru" /> - Georgia Institute of Technology</li>
                    <li><SecureLinkNT url="https://reda-ammar.uconn.edu/" text="Reda A. Ammar" /> - University of Connecticut</li>
                    <li><SecureLinkNT url="http://www.cs.ucr.edu/~jiang/" text="Tao Jiang" /> - University of California, Riverside</li>
                    <li><SecureLinkNT url="https://www-users.cs.umn.edu/~kumar001/" text="Vipin Kumar" /> - University of Minnesota</li>
                    <li><SecureLinkNT url="https://cs.uwaterloo.ca/~mli/" text="Ming Li" /> - University of Waterloo</li>
                    <li><SecureLinkNT url="https://users.cs.duke.edu/~reif/" text="John Reif" /> - Duke University</li>
                    <li><SecureLinkNT url="https://www.cise.ufl.edu/~sahni/" text="Sartaj Sahni" /> - University of Florida</li>
                </ul>
                
                <h5>Publicity Chairs</h5>
                <ul style={ulstyle}>
                <li><SecureLinkNT url="https://www.linkedin.com/in/olga-glebova-5b4991136/" text="Olga Glebova" /> - University of Connecticut</li>
                </ul>

                <h5>Webmaster</h5>
                <ul style={ulstyle}>
                    <li><SecureLinkNT url="https://ranykamel.dev" text="Rany Kamel" /> - University of Connecticut</li>
                </ul>

                <h5>Program Committee</h5>
                <ul style={ulstyle}>
                    <li><SecureLinkNT url="https://www.researchgate.net/profile/Pavel-Skums" text="Pavel Skums" /> - University of Connecticut (Chair)</li>
                    <li><SecureLinkNT url="https://www.ou.edu/coe/cs/people/moussa" text="Marmar Moussa" /> - University of Oklahoma</li>
                    <li><SecureLinkNT url="https://mukul-bansal.uconn.edu/" text="Mukul Bansal" /> - University of Connecticut</li>
                    <li><SecureLinkNT url="https://www.derekaguiar.com/" text="Derek Aguiar" /> - University of Connecticut</li>
                    <li><SecureLinkNT url="https://yufeng-wu.uconn.edu/" text="Yufeng Wu" /> - University of Connecticut</li>
                    <li><SecureLinkNT url="https://www.csc.ncsu.edu/people/svalliy" text="Sharma V. Thankachan" /> - North Carolina State University (Chair)</li>
                    <li><SecureLinkNT url="https://www.southernct.edu/directory/alseesis1" text="Sahar Al Seesi" /> - Southern Connecticut State University</li>
                    <li><SecureLinkNT url="https://cbi.gwu.edu/max-alekseyev" text="Max Alekseyev" /> - George Washington University</li>
                    <li><SecureLinkNT url="https://scholar.google.com/citations?user=XjCGeVYAAAAJ&amp;hl=en" text="Jaime Davila" /> - Mayo Clinic</li>
                    <li><SecureLinkNT url="https://profesores.virtual.uniandes.edu.co/jaduitama/en/inicio-en/" text="Jorge Duitama" /> - Universidad de los Andes, Colombia</li>
                    <li><SecureLinkNT url="https://research.unsw.edu.au/people/dr-richard-edwards" text="Richard Edwards" /> - University of New South Wales, Australia</li>
                    <li><SecureLinkNT url="http://web.cs.iastate.edu/~oeulenst/page.2010.11/nexus23_about.html" text="Oliver Eulenstein" /> - Iowa State University</li>
                    <li><SecureLinkNT url="http://dkrizanc.web.wesleyan.edu/" text="Danny Krizanc" /> - Wesleyan University</li>
                    <li><SecureLinkNT url="http://web.itu.edu.tr/kulekci/" text="M. Oğuzhan Külekci" /> - Istanbul Technical University, Turkey</li>
                    <li><SecureLinkNT url="http://www-etud.iro.umontreal.ca/~lafonman/" text="Manuel Lafond" /> - Université de Montréal, Canada</li>
                    {/*
                    <li><SecureLinkNT url="https://www.lisanwanglab.org/~yyee/" text="Yuk Yee (Fanny) Leung" /> - University of Pennsylvania</li>
                    */}
                    <li><SecureLinkNT url="http://dnapunctuation.org/~poptsova/" text="Maria Poptsova" /> - Moscow State University, Russia</li>
                    <li><SecureLinkNT url="https://www.egr.msu.edu/people/profile/yannisun" text="Yanni Sun" /> - Michigan State University</li>
                    <li><SecureLinkNT url="http://faculty.cs.tamu.edu/shsze/" text="Sing-Hoi Sze" /> - Texas A&amp;M University</li>
                    <li><SecureLinkNT url="http://www.dia.unisa.it/professori/uv" text="Ugo Vaccaro" /> - University of Salerno, Italy</li>
                    <li><SecureLinkNT url="https://scholar.google.com/citations?user=e_YXLdYAAAAJ&hl=en" text="Balaji Venkatachalam" /> - Google</li>
                    {/*
                    <li><SecureLinkNT url="https://scholar.google.com/citations?user=rU21N2AAAAAJ&hl=en" text="Tianyu Wang" /> - Amazon</li>
                    */}
                    <li><SecureLinkNT url="https://scholar.google.com/citations?user=7pgY2F0AAAAJ&hl=en" text="Jianxin Wang" /> - Central South University, China</li>
                    <li><SecureLinkNT url="http://homepage.usask.ca/~faw341/" text="Fang Xiang Wu" /> - University of Saskatchewan, Canada</li>
                    <li><SecureLinkNT url="http://www.cs.ucf.edu/~syooseph/" text="Shibu Yooseph" /> - University of Central Florida</li>
                    <li><SecureLinkNT url="http://www.cs.ucf.edu/~shzhang/" text="Shaojie Zhang" /> - University of Central Florida</li>
                    <li><SecureLinkNT url="http://www-users.cs.umn.edu/~weizhang/" text="Wei Zhang" /> - University of Central Florida</li>
                    <li><SecureLinkNT url="https://bioengr.ku.edu/cuncong-zhong-phd" text="Cuncong Zhong" /> - University of Kansas</li>
                    <li><SecureLinkNT url="https://dna.engr.uconn.edu/ion/" text="Ion Mandoiu" /> - University of Connecticut</li>
                    <li><SecureLinkNT url="https://alan.cs.gsu.edu/NGS/?q=cscazz" text="Alexander Zelikovsky" /> - Georgia State University</li>
                    <li><SecureLinkNT url="https://personal.utdallas.edu/~djg230002/" text="Daniel Gibney" /> - University of Texas at Dallas</li>
                </ul>

                {/* <p style={{ fontSize: "16px", marginTop: "8px" }}>
                    Other conference committee members to be posted soon.
                </p> */}
            </Card>
        </>
    )
}




export default Committees;