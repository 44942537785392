/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { HashRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "pages/main";

import { useState } from "react";

// Material Kit 2 React routes
import routes from "routes";

import FooterSponsor from "footer-sponsor";
import MainCard from "maincard";

export default function App() {
  const { pathname } = useLocation();
  //const size = useWindowSize();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname, window.innerHeight]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    const [mainCardMarginTop, setMainCardMarginTop] = useState(512);
    //const otherCardRef = useRef(null);
    
    // Change maincard margin based on current page
    useEffect(() => {
      //console.log("OCR");
      //console.log(otherCardRect);
      function resizeMargin() {
        var otherCardElem = document.getElementById("other-card");
        //var otherCardRect = otherCardElem.getBoundingClientRect();
        if (pathname === "/") {
          setMainCardMarginTop(otherCardElem.offsetTop + (otherCardElem.offsetTop / 30));
        } else {
          setMainCardMarginTop(otherCardElem.offsetTop - (otherCardElem.offsetTop / 20));
        }
        //console.log("AAAA");
        //console.log(otherCardElem.offsetTop);
        //console.log(otherCardElem.offsetHeight);
      }
      resizeMargin();
/*       if (pathname === "/") {
        setMainCardMarginTop(512);
      } else {
        setMainCardMarginTop(400);
      } */
      window.addEventListener("resize", resizeMargin);
    }, [pathname]);
    
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainCard margin_top={`${mainCardMarginTop - (pathname === "/" ? 175 : 201)}px`} margin_bottom={`${-mainCardMarginTop}px`} show_dates={pathname != "/"} />
      <Routes>
        {getRoutes(routes)}
        <Route exact path="*" element={<Presentation />} />
        <Route exact path="*" element={<Navigate to="/" />} />
        
      </Routes>
      <FooterSponsor />
    </ThemeProvider>
  );
}