import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";
import {Link} from "react-router-dom";

function CallForPapers() {

    return (
        <>
            <MKBox
                    minHeight="30vh"
                    width="100%"
                    sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                    }}
            ></MKBox>

            <Card id="other-card"
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 8 },
                    mt: `${180}px`,
                    mb: 4,
                }}
            >
                <p style={{ fontSize: "16px", textAlign: "right" }}>
                    <a href="/resources/iccabs_cfp.pdf" target="_blank" rel="noopener noreferrer">For a PDF version of the Call for Papers, click here.</a>
                </p>
                <div style={{ textAlign: "center" }}>
                    <h3>
                        Call for Papers
                    </h3>
                    <p style={{ fontSize: "16px", margin: "0 30vw" }}>
                        12th International Conference on Computational Advances in Bio and Medical Sciences (ICCABS 2023)
                        <br />
                        Location: University of Oklahoma, Norman, Oklahoma
                        <br />
                        Dates: December 11 - 13, 2023
                    </p>
                </div>
                <br />

                <div style={{ marginLeft: "10vw", marginRight: "10vw" }}>
                    <p style={{ fontSize: "16px" }}>
                    Advances in technologies such as high-throughput and single-cell sequencing 
                    are profoundly transforming life sciences and public health, resulting in 
                    the collection of unprecedented amounts of biological and medical data. 
                    Using this data to advance our knowledge about fundamental biological processes and 
                    improve human health requires novel computational models and advanced analysis algorithms. 
                    ICCABS aims to bring together leading academic and industry researchers to discuss the 
                    latest advances in computational methods for bio and medical sciences. 
                    </p>
                    <br />
                    <p style={{ fontSize: "16px", maxWidth: "500px" }}>
                        <b>Topics of interest include but are not limited to:</b>
                    </p>
                    <br />
                    <p>
                        <ul style={{ fontSize: "16px", marginLeft: "50px" }}>
                            <li><b>Biological Big Data Analytics</b> </li>
                            <li><b>Biological modeling and simulation</b> (Molecular and cellular modeling, stochastic and rule-based modeling, modeling languages and systems)</li>
                            <li><b>Biomedical image processing</b> (Image segmentation and classification, visualization, functional and molecular imaging)</li>
                            <li><b>	Biomedical data and literature mining</b> (Data integration, knowledge discovery from electronic medical records and scientific literature)</li>
                            <li><b>	Computational genetic epidemiology</b> (Linkage and association analysis, gene-gene and gene-environment interaction modeling, genetic risk analysis)</li>
                            <li><b>	Computational metabolomics</b> (Metabolomics databases, metabolite identification, spectral analysis, metabolic network modeling)</li>
                            <li><b>	Computational proteomics</b> (Peptide identification and quantification, post-translational modifications, protein-protein interactions)</li>
                            <li><b>	Databases and ontologies</b> (Biomedical data warehouses, database integration, biomedical ontologies and semantic web services)</li>
                            <li><b>	Data Integration</b> (Records linkage and the integration of biological data)</li>
                            <li><b>	Gene regulation</b> (Regulatory motifs and modules, post-transcriptional regulation, regulatory networks)</li>
                            <li><b>	Genome analysis</b> (Genome assembly, genome annotation, comparative genomics, metagenomics)</li>
                            <li><b>	Health Informatics</b> (Medical data management and privacy, medical recommender systems, therapy optimization)</li>
                            <li><b>	High-performance bio-computing</b> (Cloud and grid computing, advanced multi-core, GPU, and FPGA biomedical applications)</li>
                            <li><b>	Immunoinformatics</b> (Epitope prediction, vaccine design, immune system simulators)</li>
                            <li><b>	Microbial Data Analysis</b> (The role of microbiomes in determining phenotypes)</li>
                            <li><b>	Molecular evolution</b> (Models of evolution, reconstruction of phylogenetic trees and networks, comparative genomics)</li>
                            <li><b>	Population genomics</b> (Haplotype and recombination analysis, structural genomic variation, signatures of natural selection)</li>
                            <li><b>	Sequence analysis</b> (Multiple sequence alignment, motif discovery, sequence search and clustering)</li>
                            <li><b>	Structural bioinformatics</b> (RNA and protein structure prediction and classification, molecular docking, RNA and protein design)</li>
                            <li><b>	Systems biology</b> (Systems approaches to molecular biology, multi-scale modeling, biological networks, synthetic biology)</li>
                            <li><b>	Transcriptomics</b> (Microarray and sequencing-based transcriptome profiling, novel transcript discovery, alternative splicing, non-coding RNA analysis).</li>
                        </ul>
                    </p>
                    <br />
                    <p style={{ fontSize: "16px" }}>
                    In addition, the conference will include special workshops on 
                    Computational Advances in Next-Generation Sequencing (CANGS), 
                    Computational Advances in Molecular Epidemiology (CAME), 
                    Computational Advances for Single-Cell Omics Data Analysis (CASCODA) and Advances in Systems Immunology (ASI). 
                    The workshops are by invitation only. If you would like to inquire about the possibility of being invited, 
                    please contact the workshop chairs as soon as possible, but no later than November 8th, 2023.
                    </p>
                    <br />
                    <p style={{ fontSize: "16px" }}>
                    <b>Submission Instructions:</b> Authors are invited to electronically submit extended abstracts in 
                    PDF in easy chair: <a href="https://easychair.org/conferences/?conf=iccabs2023" target="_blank" rel="noopener noreferrer">https://easychair.org/conferences/?conf=iccabs2023</a><br/><br/>

                    Track 1: Extended abstracts to be published in Lecture Notes in Bioinformatics. 
                    Submissions should be prepared using the Springer LNBI templates and guidelines, and should not exceed 12 pages. 
                    Selected submissions will be invited to special issues of the Journal of 
                    Computational Biology (JCB) or Transactions on Computational Biology and Bioinformatics (TCBB) dedicated to the conference. <br/><br/>

                    Track 2: Posters. Submissions should not exceed 2 pages. <br/><br/>
                    Track 3: Highlight talks. Submissions may include previously published or currently submitted results. 
                    Submissions should not exceed 2 pages. <br/><br/>

                    A limited number of student awards will be made to cover registration fees (conditional upon NSF support). 

                    </p>

                </div>

            </Card>
        </>
    )
}

export default CallForPapers;