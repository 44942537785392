import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { useState } from "react";

function ProgramButton() {
    const [hovering, setHovering] = useState(false);

    return (
        <button 
            onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}
            style={{ marginLeft: "37vw", marginRight: "37vw", marginTop: "8px", marginBottom: "1rem", padding: "0.5rem 1rem", 
                    backgroundColor: hovering ? "#7C6D24" : "#AFAF00",
                    color: "#fff",
                    fontWeight: "bold",
                    borderWidth: "3px",    
                    borderStyle: "solid",
                    borderColor: "#7C6D24 #7C6D24 #7C6D24 #7C6D24",
                    borderRadius: "5px",
                    transition: "all .2s ease",
                    WebkitTransition: "all .2s ease",
                    MozTransition: "all .2s ease",
                    textAlign: "center",                        
                }} 
            onClick={() => window.open("https://easychair.org/smart-program/ICCABS2023/", "_blank", "noopener noreferrer")}>
            ICCABS 2023 Program
        </button>
    )
}


function Program() {


    return (
        <>
            <MKBox
                        minHeight="30vh"
                        width="100%"
                        sx={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                        }}
                ></MKBox>

                <Card id="other-card"
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 8 },
                        mt: `${180}px`,
                        mb: 4,
                    }}
                >

                    <h3>Program</h3>

                    <p style={{ fontSize: "24px", marginTop: "4px" }}>
                        <b>
                        The ICCABS 2023 Program can be accessed by clicking the button below.
                        </b>
                    </p>

                    <ProgramButton />

                    <br />

                    <p style={{ color: "red", fontSize: "16px" }}>
                        Please make sure to check here regularly for program updates!
                    </p>


                </Card>
        </>
    )
}


export default Program;