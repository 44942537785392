import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import routes from "routes";

function Navbutton(prop) {
  
  const navigate = useNavigate();

  const [hovering, setHovering] = useState(false);

  const routeChange = () => {
    let path = `/${prop.page}`;
    navigate(path);
  };
    
  let curLocation = useLocation();
  let isCurrent = curLocation.pathname === `/${prop.page}`;

  //if (isCurrent && curLocation.pathname === "/") {
  //  return (<></>);
  //}

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={routeChange}
      style={{
        backgroundColor: (!isCurrent && hovering) ? "#444" : "#333",
        fontWeight: isCurrent ? "bold" : "normal",
        // fontSize in terms of width
        fontSize: isCurrent ? "1.25vw" : "1vw",
        transition: "all .5s ease",
        WebkitTransition: "all .5s ease",
        MozTransition: "all .5s ease",
        color: "#fff",
        border: "none",
        //marginRight: "1rem",
        margin: "0px",
        padding: "1rem",
        display: "inline-block",
        whiteSpace: "nowrap",
        //borderStyle: isCurrent ? "solid" : "none",
        //borderColor: "#ccc",
        //borderWidth: "1px",
        width: isCurrent ? `${prop.width * 1.25}px` : `${prop.width}px`,
        textAlign: "center",
        verticalAlign: "middle",
        maxHeight: "3.5rem",
      }}
      >
        {prop.item}
      </div>
    );
  }
  
  function Navbar() {
    // length of routes
    const numRoutes = routes.length;
    // get width of navbar, update on resize
    const [width, setWidth] = useState(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    // calculate width of each button
    const buttonWidth = width / numRoutes;

    // Navbar minHeight in terms of buttonWidth
    return (
      <nav style={{ backgroundColor: "#333", width: "100%", marginBottom: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {routes.map((prop, key) => {
            return (
              <Navbutton key={key} item={prop.name} page={prop.route} width={buttonWidth} />
            );
          })}
      </div>
    </nav>
  );
}


export default Navbar;