import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { useState } from "react";

function RegistrationButton() {
    const [hovering, setHovering] = useState(false);

    return (
        <button 
            onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}
            style={{ marginLeft: "1rem", marginTop: "8px", marginBottom: "1rem", padding: "0.5rem 1rem", 
                    backgroundColor: hovering ? "#2C652E" : "#4EAF52",
                    color: "#fff",
                    fontWeight: "bold",
                    borderWidth: "3px",    
                    borderStyle: "solid",
                    borderColor: "#2C652E #2C652E #2C652E #2C652E",
                    borderRadius: "5px",
                    transition: "all .2s ease",
                    WebkitTransition: "all .2s ease",
                    MozTransition: "all .2s ease",                        
                }} 
            onClick={() => window.open("https://oupacsrcc.ungerboeck.net/prod/emc00/register.aspx?aat=314f58716c43334a78654f2b4a566133514667635455737935724574774e4c78465248596b38474c4b46673d", "_blank", "noopener noreferrer")}>
            Register to ICCABS 2023
        </button>
    )
}


function Registration() {


    return (
        <>
            <MKBox
                        minHeight="30vh"
                        width="100%"
                        sx={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                        }}
                ></MKBox>

                <Card id="other-card"
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 8 },
                        mt: `${180}px`,
                        mb: 4,
                    }}
                >

                    <h3>Registration</h3>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "4px" }}>
                        <Container maxWidth="sm" textAlign="center">
                        
                            <p style={{ fontSize: "16px" }}>

                                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                                    The registration website is now available. 
                                </p>

                                Click the button below to register.
                                <br/>
                                <RegistrationButton />
                                <br/><br/>

                                



                                

                                As a reminder, for those who have papers or posters accepted, 
                                it is required that at least one author registers to attend the conference and present the paper or the poster. 
                                <br/><br/>

                                Each accepted paper should have a distinct <b>FULL registration</b>. 
                                {/*
                                A <b>FULL registration</b> means $600 for early regular registration, $700 for late regular registration. 
                                The author can give a 25 minute presentation in the conference.
                                */}

                                <br/><br/>

                                Each accepted poster should have a distinct <b>poster registration</b>.&nbsp;
                                {/*
                                A <b>poster registration</b> means $400 for Poster Registration (early registration) and $450 for Poster Registration (late registration).
                                */}

                                Workshop speakers (by invitiation) need only to register for one workshop.
                                Your conference registration automatically covers workshops&apos; attendance.

                                <br/><br/>
                                ICCABS 2023 plans to support some number of student grants (subject to NSF fundings). 
                                Please feel free to go to the <Link to="/travel-award"><b>Student Award</b></Link> section for more information.

                                <br/><br/>

{/*                                 If you are a student applying for a Student Award, please check the &ldquo;pay later&rdquo; option, which will
                                defer your registration fees until you receive your Student Award notification.
                                <br/><br/> */}
                                
                                <div style={{ display: "flex" }}>
                                    <b>
                                    ICCBAS is offering a <b>20% discount</b> off conference registration fees for ISCB members. 
                                    We encourage meeting attendees to&nbsp;
                                    <a href="https://www.iscb.org/become-a-member" target="_blank" rel="noopener noreferrer"><b>join ISCB</b></a>
                                    &nbsp;in order to take advantage of the member discount. 
                                    </b>
                                    <a href="https://www.iscb.org/" target="_blank" rel="noopener noreferrer">
                                        <img src={require("assets/media/sponsors/iscb-logo-Affiliated-Conference-2014.gif")} alt = "ISCB" 
                                        style={{ maxWidth: "100px", float: "right" }} />
                                    </a>
                                </div>
                                
                                <br/><br/>
                            </p>

                        </Container>
                        <Container textAlign="right">
                            <p style={{ fontSize: "16px", textAlign: "right" }}>
                                <h4>Conference <u>Advance</u> Registration Fees (by Nov. 15): </h4>

                                    <p><i>Regular Registration:</i> <b>$600</b></p>
                                    <p><i>Student Registration:</i> <b>$400</b></p>
                                    <p><i>Poster Registration:</i> <b>$400</b></p>
                                    
                                <br/>

                                <h4> Conference <u>Late</u> / Onsite Registration Fees: </h4>

                                        <p><i>Regular Registration:</i> <b>$700</b></p>
                                        <p><i>Student Registration:</i> <b>$450</b></p>
                                        <p><i>Poster Registration:</i> <b>$450</b></p>

                                <br/>

                                <h4>Workshop Registration Fees: </h4>

                                        <p><i>Regular Registration (<u>Early</u>):</i> <b>$500</b></p>
                                        <p><i>Regular Registration (<u>Late</u>):</i> <b>$550</b></p>
                                        <p><i>Student Registration (<u>Early</u>):</i> <b>$350</b></p>
                                        <p><i>Student Registration (<u>Late</u>):</i> <b>$400</b></p>

                                <br/>
                                <h4>Invited Speakers <u>Advance</u> Registration Fees: $350</h4>
                                <h4>Invited Speakers <u>Late</u> Registration Fees: $400</h4>
                                
                            </p>
                        </Container>    

                        
                    </div>

                </Card>
        </>
    )
}


export default Registration;