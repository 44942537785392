import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";


function TravelAward() {
    return (
        <>
            <MKBox
            minHeight="30vh"
            width="100%"
            sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
            }}
            ></MKBox>

            <Card id="other-card"
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 8 },
                    mt: `${180}px`,
                    mb: 4,
                }}
            >

                <Venue />
                <Hotel />
                <NormanActivities />
                <StudentAward />
            </Card>
        </>
    )
}

function Venue() {
    return (
        <>
            <h3>Venue</h3>
            <p style={{ fontSize: "16px", marginLeft: "1rem" }}>
                The conference will take place in the&nbsp;
                <a href="https://www.ou.edu/union" target="_blank" rel="noopener noreferrer">Oklahoma Memorial Union Building</a> at 
                the <a href="https://www.ou.edu/" target="_blank" rel="noopener noreferrer">University of Oklahoma</a>.
                <br />
                A map of the building can be found <a href="https://www.ou.edu/content/dam/union/docs/OMUBuildingMap.pdf" target="_blank" rel="noopener noreferrer">here</a>.
            </p>

            <br style={{ marginBottom: "1rem" }} />
        </>
    )
}

function Hotel() {
    return (
        <>
            <h3>Hotel Information</h3>
            <div>
            <p style={{ fontSize: "16px", marginLeft: "1rem"}}>
                <a href="https://nounhotel.com/" target="_blank" rel="noopener noreferrer">
                    Noun Hotel</a> is located at 542 South University Blvd, Norman, OK, right by the University of
                    Oklahoma campus.
                    <br />
                    They are providing conference attendees discounted rates of <b>$179</b> for single or double queen rooms until <b>November 30th</b>.
                    <br />
                    Please call the hotel front desk directly at 1-(405)-701-5858 and request the ICCABS conference room block to
                    receive the discounted room rate.
            </p>
            </div>
            <br style={{ marginBottom: "1rem" }} />
        </>
    )
}

function NormanActivities() {
    return (
        <>
            <h3>Attractions</h3>
            <p style={{ fontSize: "16px", marginLeft: "1rem" }}>
                Norman, OK is a vibrant college town with many attractions. For a resource on events and things to do in Norman, please visit&nbsp;
                <a href="https://www.visitnorman.com" target="_blank" rel="noopener noreferrer">https://www.visitnorman.com</a>.
            </p>
            <br style={{ marginBottom: "1rem" }} />
        </>
    )}

function StudentAward() {

    return (
        <>
            <h3>Student Award</h3>
            <p style={{ fontSize: "16px", marginLeft: "1rem" }}>
            
                <p>ICCABS 2023 plans to support some number of student grants (subject to NSF fundings). 
                    In particular, this money can be used for the following items:</p>
                <ul style={{ marginLeft: "2rem" }}>
                    <li>Registration fee</li>
                    <li>Travel</li>
                    {/* <li>Hotel accommodation</li>
                    <li>Airfare</li>
                    <li>Food and other expenses</li> */}
                </ul><br/>
                <p>The applicants should submit the following materials to <Link to="/contact-us">the {"conference's"} general chair</Link> 
                    <span style={{ color: "green" }}> before Nov. 8, 2023 by 5PM EST.</span></p>
                <p><span style={{ color: "red" }}>(Student Awards Applications are now closed)</span></p>
                <ul style={{ marginLeft: "2rem" }}>
                    <li>Number and title of a paper or poster that has been accepted (if any) to ICCABS</li>
                    <li>A brief summary of the research interests of the student together with an explanation of how this conference will help her/his research efforts</li>
                    <li>A support letter from the student{"'"}s supervisor/advisor that indicates that the
                    student is in good academic standing and how the student will benefit from attending this conference</li>
                    <li>An estimate on the total expenses and availability of other sources of funding</li>
                </ul>
                <br/>
                <p>
                    Preference will be given to students presenting their works in the conference (including workshops).
                </p>
            </p>
        </>
    )
}


export default TravelAward;