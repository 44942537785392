import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";
import Card from "@mui/material/Card";
import { useState } from "react";
import { Link } from "react-router-dom";


function PaperSubmitButton() {
    const [hovering, setHovering] = useState(false);

    return (
        <button 
            onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}
            style={{ marginLeft: "1rem", marginTop: "8px", marginBottom: "1rem", padding: "0.5rem 1rem", 
                    backgroundColor: hovering ? "#0066cc" : "#0099ff",
                    color: "#fff",
                    fontWeight: "bold",
                    borderWidth: "3px",    
                    borderStyle: "solid",
                    borderColor: "#0066cc #003d99 #003d99 #0066cc",
                    borderRadius: "5px",
                    transition: "all .2s ease",
                    WebkitTransition: "all .2s ease",
                    MozTransition: "all .2s ease",                        
                }} 
            onClick={() => window.open("https://easychair.org/conferences/?conf=iccabs2023", "_blank", "noopener noreferrer")}>
            Submit Paper
        </button>
    )
}

function PaperSub() {


    return (
        <>
            <MKBox
                        minHeight="30vh"
                        width="100%"
                        sx={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                        }}
                ></MKBox>

                <Card id="other-card"
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 8 },
                        mt: `${180}px`,
                        mb: 4,
                    }}
                >

                    <h3>Paper Submission</h3>

                    <p style={{ fontSize: "16px", marginTop: "4px" }}>
                        Paper, abstract, and poster submission is now available. Click the button below to submit via EasyChair.
                        <br />
                        
                        <PaperSubmitButton />

                        <br />
                        For extension requests or other questions, please <Link to="/contact-us">contact us</Link>.
                        
                    </p>

                    <h4 style={{ marginTop: "1rem" }}>
                        Format Instructions</h4>

                    <p style={{ fontSize: "16px", marginLeft: "1rem" }}>
                        Submissions should be prepared using the&nbsp;
                        <a href="https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines"  target="_blank" rel="noopener noreferrer">
                        Springer LNBI templates and guidelines.</a><br/>

                        <br/>Abstract and Poster submissions should not exceed <b>1-2 pages</b>.<br/>

                        <br/>Full Paper submissions should not exceed <b>12 pages</b> in length.<br/><br/>


                        {/* TODO: email Raj about broken link */}
                        {/* A <b>Consent to Publish</b> form should also be completed and 
                        submitted together with the camera-ready version of the paper to easychair. 
                        The form can be downloaded via the following link.<br><br>

                        <a href="https://resource-cms.springernature.com/springer-cms/rest/v1/content/15433008/data/Contract_Book_Contributor_Consent_to_Publish_LNCS_SIP">
                            https://resource-cms.springernature.com/springer-cms/rest/v1/content/15433008/data/Contract_Book_Contributor_Consent_to_Publish_LNCS_SIP</a><br><br>
    */}
                        The <b>Conference Name</b> should <font color="red">exactly</font> be: <br/>
                        <p style={{ marginLeft: "1rem", fontStyle: "italic" }}>
                            <b>2023 12th International Conference on Computational Advances in Bio and Medical Sciences (ICCABS)</b>. 
                        </p>
                        
                        {/* <br/>
                        The <b>Volume Editor Name</b> is <b>Sanguthevar Rajasekaran</b>. */}
                    </p>


                </Card>
        </>
    )
}


export default PaperSub;