import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";

function ContactUs() {

    return (
        <>
            <MKBox
                    minHeight="30vh"
                    width="100%"
                    sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                    }}
            ></MKBox>

            <Card id="other-card"
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 8 },
                    mt: `${180}px`,
                    mb: 4,
                }}
            >
                <h3>General Chair Contact Information:</h3>
                <div>
                    Dr. Marmar Moussa <br />
                    Email: <a href="mailto:marmar.moussa@ou.edu">
                    marmar.moussa@ou.edu</a> <br />
                    Website: <a href="https://www.ou.edu/coe/cs/people/moussa"
                        target="_blank" rel="noopener noreferrer">
                        https://www.ou.edu/coe/cs/people/moussa</a>
                    {/* Dr. Sanguthevar Rajasekaran<br />
                    Email: <a href="mailto:sanguthevar.rajasekaran@uconn.edu">
                    sanguthevar.rajasekaran@uconn.edu</a><br />
                    Website: <a href="https://raj.cse.uconn.edu/"
                        target="_blank" rel="noopener noreferrer">
                        https://raj.cse.uconn.edu/</a> */}
                </div>
            </Card>
            
        </>
    )
}

export default ContactUs;