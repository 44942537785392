import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import bgImage from "assets/media/1677220150203.png";

function KeyNoteEntry(prop) {
    const affiliation = prop.affiliations.map((affiliation) => {
        return (
                <h6 key={affiliation}>{affiliation}</h6>
        )
    })

    const width_ratio = 0.75
    const float_child_left = { float: "left", width: `${width_ratio * 100}%`, padding : "1rem", textAlign: "left" }
    const float_child_right = { float: "right", width: `${(1-width_ratio) * 100}%`, textAlign: "right" }

    return (
        <div style={{ overflow: "auto", padding: "1rem", borderBottom: prop.dotted ? "5px dotted #c5c5c5" : "none", marginTop: "1rem" }}>
            <h4>{prop.title}</h4>
            <div style={{ width: `${width_ratio*100}%`, float: "left" }}>
                <h5>{prop.speaker}</h5>
                <p style={{ fontSize: "16px", marginLeft: "1rem" }}>
                    {affiliation}
                </p>
            </div>

            <div style={{ ...float_child_right }}>
                <img src={prop.speaker_img} alt={prop.speaker} style={{ maxHeight: "10vw" }} />
            </div>

            <div style={{ ...float_child_left }}>
                
                <p style={{ fontSize: "16px" }}>
                    
                    <p style={{ fontSize: "14px" }}>
                        <p style={{ marginBottom: "1rem" }}>
                            <h4>Abstract</h4>
                            {prop.abstract}
                        </p>

                        <h4>Bio</h4>
                        {prop.bio}
                    </p>
                </p>
                
                
                
            </div>

        </div>
    )
}

const entries = {
    "Growing Opportunities for Computational Advancements in Biomedical Research": {
        speaker: "Dr. Courtney Montgomery, PhD",
        speaker_img: require("assets/media/speakers/courtney.jpg"),
        affiliations: [
                    "Director, Center for Biomedical Data Sciences", 
                    "Director, Sarcoidosis Research Unit",
                    "Professor, Genes and Human Disease Research Program",
                    "Oklahoma Medical Research Foundation"
                    ],
        abstract: "The last few years have seen both in technologies to generate whole genome sequencing, \
                    spatial and single-cell spatial transcriptomic, high-throughput proteomic, high-resolution molecular, \
                    and quantitative imaging data, as well as advances in and increased accessibility of artificial intelligence \
                    and deep learning methods. These data and methods have the potential to revolutionize our understanding of any number \
                    of health-related phenomena but are virtually useless without the ability to process and analyze them in an efficient way. \
                    This offers an unprecedented opportunity to the data science community. \
                    The purpose of my talk is to discuss these opportunities including the emerging technologies \
                    that are motivating them and the data they are creating. By presenting this information along with examples of \
                    the application of integrative analytical methods, specifically network-based and machine learning approaches to \
                    clinical and genomic data, to help data scientists and medical researchers optimize, expand, and innovate their \
                    current research practices.",
        bio: "Dr. Montgomery is the Director of the OMRF Center for Biomedical Data Sciences (CBDS), \
                and a Professor in the Genes and Human Disease Research Program at the Oklahoma Medical Research Foundation (OMRF).  \
                She is also an Adjunct Professor in the Department of Biostatistics and Epidemiology in the \
                College of Public Health and the School of Computer Science at the University of Oklahoma. \
                She has been engaged in complex disease research for >20 years with a strong focus on “big data” and \
                translational research, particularly genetics and genomics. As an early-stage investigator at Case Western \
                Reserve she worked on the development, refinement, and application of methodology for statistical analysis of \
                genetic data with a special emphasis on modeling methods for highly correlated data and the implementation of \
                these methods into the SAGE software suite as the Assistant Director of the NIH funded Human Genetic Analysis Resource. \
                Since then, she has directed Bioinformatics and Data Analysis Cores for both a Center of Biomedical Research Excellence (COBRE), \
                a Center for Research Translation, and she directed the OMRF Quantitative Analysis Core for over 10 years. \
                She also leads her own laboratory focused on the immunogenetics of sarcoidosis, particularly in African American patients and, \
                as such, serves as Director of the OMRF Sarcoidosis Research Unit (SRU). \
                Her collaborative work centers on the application of the data sciences  to clinical and biological data.  \
                She has extensive experience in genomic, immunological, and other large-scale, high-dimension data, and \
                continues to explore methods of data integration and network development."
        
    },
    "Unraveling the Early-Life Origins of Nonalcoholic Fatty Liver Disease (NAFLD) Using Multi-'Omics Approaches": {
        speaker: "Karen Jonscher, PhD",
        speaker_img: require("assets/media/speakers/jonscher.jpg"),
        affiliations: [
                    "Associate Professor, HAROLD HAMM DIABETES CENTER",
                    "Department of Biochemistry and Molecular Biology"
                    ],
        abstract: "The use of multi-'omics, a comprehensive approach integrating genomics, transcriptomics, \
                    proteomics, metabolomics, and epigenetics, has emerged as a transformative paradigm in biomedical research. \
                    This holistic strategy provides a deep understanding of biological systems, \
                    revealing intricate molecular mechanisms and the complex interplay of factors underlying health and disease. \
                    Our research program applies a systems-biology approach to investigate cellular processes and molecular mechanisms \
                    in liver, gut, fat, and bone marrow, with a focus on understanding the early life origins of adult disease. \
                    An increasing body of evidence suggests that maternal obesity, approaching 50% in the US, primes offspring in \
                    utero and increases their risk of developing NAFLD and obesity in later life. NAFLD and obesity are also key risk \
                    factors for increased prevalence of diabetes and liver cancer in both children and adults. Recent mouse studies \
                    from our lab have shown that the developing immune system is particularly susceptible to maternal diet and gut \
                    microbiome dysregulation, with metabolic and inflammatory reprogramming of innate immune cells in the liver and \
                    bone marrow associated with accelerated NAFLD progression in later life, characterized by oxidative stress, \
                    inflammation, and fibrosis. We have identified the antioxidant pyrroloquinoline quinone and microbial metabolites \
                    indole and indole-3-acetate as dietary interventions that, when administered during pregnancy and/or lactation, \
                    effectively halt the programming of inflammatory sequelae associated with metabolic disease risk in offspring. \
                    Our work, spanning cells and tissues in mice and non-human primates, uses single cell sequencing, metabolomics, \
                    and microbiome and bioinformatic approaches to elucidate microbiome - immune cell - tissue crosstalk with the goal \
                    of understanding complex mechanisms and interacting pathways underlying NAFLD’s early origins. The identification \
                    of circulating and tissue biomarkers, molecular pathways, and potential therapeutic targets is a crucial aspect of \
                    our work, propelling us towards more effective means for preventing developmental programming by designing interventions \
                    to mitigate the long-term health impacts of NAFLD.",
        bio: "The rate of obesity among women of childbearing age continues to rise, approaching 50%. The obesity-related disease, \
                nonalcoholic fatty liver disease (NAFLD), is now the most prevalent liver disease worldwide and is a key risk \
                factor for diabetes and liver cancer. Therefore, understanding why a mother’s obesity primes \
                her child in utero to increase risk for developing NAFLD in later life is a critical question with significant relevance to human health. \
                Dr. Jonscher’s training in Applied Physics and mass spectrometry-based ‘omics \
                allows her to apply large-scale, interdisciplinary, systems biology-based approaches to this question. \
                Her research focuses on investigating contributions of detrimental maternal exposures, particularly those resulting \
                from consuming a Western-style diet, to development of metabolic disease in the offspring in later life. \
                Dr. Jonscher has identified pyrroloquinoline quinone, indole and indole-3-acetate as dietary interventions that, \
                when given during pregnancy and/or lactation, halt programming of inflammatory sequelae that are risk factors \
                for metabolic disease in offspring. Her current goal is to use single cell techniques and multi-‘omics to \
                elucidate mechanisms by which these dietary interventions modulate microbiome and host signaling to improve steatosis, \
                inflammation, and fibrosis in the offspring of obese pregnancy."
    },
    "Computational and modeling aspects of analyzing high-throughput genomic data": {
        speaker: "Professor Jie Chen, PhD",
        speaker_img: require("assets/media/speakers/chen.jpg"),
        affiliations: [
                    "Department of Biostatistics, Data Science and Epidemiology",
                    "Augusta University"
                    ],
        abstract: "The high-throughput sequencing technology has provided new opportunities in the scientific discovery of genetic information.  \
                    While the sequencing data pre-processing techniques have been very well developed and widely accepted by the research community, \
                    modeling and analyzing high-dimensional genomic data still post computational challenges. In this talk, I will present two of our recent projects. \
                    The first one is a framework on modelling sequencing data of multiple subjects for genomic feature discovery.  \
                    Taking into consideration of the correlated structure of high throughput genomic data, we use the framework of a fused Lasso latent feature model \
                    to solve the problem, and further propose a modified information criterion for the tuning parameter selection when searching for common features \
                    shared by multiple samples.  Simulation studies and application on DNA- sequencing data showed that the proposed approach can effectively identify \
                    individual genomic features of a single subject profile and common genomic features for multiple subjects.  The second one is for jointly analyzing \
                    multiple types of genomics data, along with prognostic information, available within and across different studies. It has been a challenging and \
                    common task in modern statistical research to use all types of data to infer disease-prone genetic information and to link those features to cancer \
                    survival.  We modelled the genomic, prognostic and survival datasets under a framework of an accelerated failure time with frailty (AFTF) to infer \
                    patients' survival time. Simulation results confirmed the good performance of the approach. The approach was applied to the analysis of the Cancer \
                    Genome Atlas (TCGA) multiple genomic datasets of Giloblastoma Multiforme (GBM), a lethal brain cancer, and interesting genomic features are identified, \
                    and biological interpretations are explored.",
        bio: "Dr. Chen is a Professor of Biostatistics and the Interim Department Chair of Department of Biostatistics, \
        Data Science and Epidemiology at Augusta University. Dr. Chen’s research interests include, but not limited to, \
        statistical change point analysis, model selection criteria, statistics in bioinformatics, biostatistics, \
        statistical modeling of high-throughput genomic data and biomedical data, high-dimensional inference and Bayesian inference. \
        Dr. Chen is the leading author of the book entitled “Parametric Statistical Change Point Analysis: With Application to Genetics, \
        Medicine, and Finance” (Birkhäuser, 1st edition 2000, Springer 2nd edition 2012), which was listed in 2020 and 2021 as the top \
        50% downloaded research monograph in the similar category by the publisher.  Dr. Chen has rich collaborative research experience \
        with biomedical scientists, resulting in collaborative publications in Science, PNAS, Blood, AHA Circulations, Bioinformatics, \
        BMC Bioinformatics, BMC Medical Genomics, IEEE/ACM Transactions on Computational Biology and Bioinformatics, among others. \
        She has served as a member of American Statistical Association (ASA) Caucus of Statistics Academic Representatives and has \
        served two successful terms as one of the two appointed ASA representatives to the national Joint Committee on Women in the \
        Mathematical Sciences (JCW). Dr. Chen was elected an ASA Fellow in 2014 and is currently the Editor-in-Chief of the Journal \
        of Applied Statistics, a Taylor & Francis journal."
    }
}

function Keynote() {
    const KeynoteEntries = Object.keys(entries).map((entry, i) => {
        return (
            <KeyNoteEntry key={entry} 
                          title={entry} 
                          speaker={entries[entry].speaker} 
                          speaker_img={entries[entry].speaker_img}
                          bio={entries[entry].bio}
                          affiliations={entries[entry].affiliations}
                          abstract={entries[entry].abstract}
                          dotted={i !== Object.keys(entries).length - 1}
            />
        )
    })

    return (
        <>
            <MKBox
                    minHeight="30vh"
                    width="100%"
                    sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                    }}
            ></MKBox>

            <Card id="other-card"
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 8 },
                    mt: `${180}px`,
                    mb: 4,
                }}
            >


                <h3 style={{ textAlign: "center" }}>
                    Keynote Speakers
                </h3>

                {KeynoteEntries}
                
                
            </Card>
            
        </>
    )
}

export default Keynote;