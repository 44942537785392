import { Link } from "react-router-dom";

function SponsorEntry(prop) {
  return (
    <a href={prop.link} target="_blank" rel="noopener noreferrer">
      <img src={prop.img} alt = {prop.alt} style={{ maxWidth: "25vw" }} />
    </a>
  );
}

function FooterSponsor() {
  let sponsor_div = {display: "flex", justifyContent: "space-between", padding: "1rem", margin: "0 auto", alignItems: "center" };
    return (
      <div style={{marginBottom: "0", paddingBottom: "5px", paddingLeft: "3vw", paddingRight: "3vw"}}>
        <h3>
          Sponsors:
        </h3>
        <div style={sponsor_div}>
          <SponsorEntry 
            link="http://www.nsf.gov/"
            img={require("assets/media/sponsors/NSF.jpeg")}
            alt="National Science Foundation"
          />
          <SponsorEntry 
            link="https://www.computer.org/web/tandc/tcpp"
            img={require("assets/media/sponsors/Springer.png")}
            alt="Springer"
          />
          <SponsorEntry 
            link="http://www.uconn.edu/"
            img={require("assets/media/sponsors/uconn.png")}
            alt="University of Connecticut"
          />
        </div>
        <div style={sponsor_div}>
          <SponsorEntry 
            link="https://www.ou.edu/"
            img={require("assets/media/sponsors/ou.webp")}
            alt="University of Oklahoma"
          />
          <SponsorEntry 
            link="https://www.ou.edu/coe/cs"
            img={require("assets/media/sponsors/CS_OU.png")}
            alt="School of Computer Science - OU"
          />
          <SponsorEntry 
            link="https://www.ou.edu/disc"
            img={require("assets/media/sponsors/DISC_OU.png")}
            alt="Data Institute for Societal Challenges - OU"
          />
        </div>




          


        <p style={{ fontSize: "15px"}}>
          If you would like to become a sponsor, please contact the {"conference's"} general chair through the <Link to="/contact-us">Contact Us</Link> page.
        </p>
      </div>
    );
}


export default FooterSponsor;