/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//import React, { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";
//import DefaultFooter from "examples/Footers/DefaultFooter";
//import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
//import Counters from "pages/Presentation/sections/Counters";
//import Information from "pages/Presentation/sections/Information";
//import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
//import Pages from "pages/Presentation/sections/Pages";
//import Testimonials from "pages/Presentation/sections/Testimonials";
//import Download from "pages/Presentation/sections/Download";

// Presentation page components
//import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
//import routes from "routes";
//import footerRoutes from "footer.routes";


// Images
import bgImage from "assets/media/seed-sower-mums.jpg";

function ICCABSLink(prop) {
  const year = prop.year;
  const is_ieee = prop.ieee;
  const link = "https://iccabs.engr.uconn.edu/" + year + "/";

  return (
    <><a href={link} target="_blank" rel="noopener noreferrer">
      {is_ieee ? "IEEE ICCABS " : "ICCABS "} {year}
    </a><br /></>
  );

}

function Presentation() {
  const oldDate = {
    textDecoration: "line-through",
    color: "red"
  }
  const newDate = {
    textDecoration: "none",
    color: "green"
  }
  return (
    <>
      {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
      /> */}
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      
      <Card id="other-card"
        sx={{
          p: 2,
          mx: { xs: 2, lg: 8 },
          mt: `${70}px`,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.4),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <MKTypography variant="h5" fontWeight="bold">
              The 12th International Conference on Computational Advances in Bio and Medical Sciences
            </MKTypography>
            <p style={{ fontSize: "16px" }}>December 11-13 2023</p>
            <p style={{ fontSize: "16px" }}>University of Oklahoma</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href="https://www.iscb.org/" target="_blank" rel="noopener noreferrer">
              <img src={require("assets/media/sponsors/iscb-logo-Affiliated-Conference-2014.gif")} alt = "ISCB" 
              style={{ maxWidth: "100px", float: "right" }} />
            </a>
            <p style={{ fontSize: "8px" }} >
              ISCB Affiliated Conference
            </p>
          </div>
        </div>


        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Container maxWidth="sm" sx={{ mt: 4 }} textAlign="left">
            <p style={{ fontSize: "16px", maxWidth: "1000px" }}>
              Advances in high-throughput technologies such as DNA sequencing and mass spectrometry
              are profoundly transforming life sciences, resulting in the collection of unprecedented
              amounts of biological and medical data. Using this data to advance our knowledge about
              fundamental biological processes and improve human health requires novel computational
              models and advanced analysis algorithms. ICCABS aims to bring together leading academic
              and industry researchers to discuss the latest advances in computational methods for bio
              and medical sciences.
            </p>
            <br />
            <p style={{ fontSize: "16px", maxWidth: "1000px" }}>
              <b>Topics of interest include but are not limited to:</b>
              <br />
              Biological Big Data Analytics, Biological modeling and simulation, Biomedical image
              processing, Biomedical data and literature mining, Computational genetic epidemiology,
              Computational metabolomics, Computational proteomics, Databases and ontologies, Gene
              regulation, Genome analysis, analysis, Health Informatics, High-performance
              bio-computing, Immunoinformatics, Molecular evolution, Population genomics, Sequence
              analysis, Structural bioinformatics, biology and Transcriptomics.
            </p>
            <br />
            <p style={{ fontSize: "16px", maxWidth: "1000px" }}>
              Extended abstracts will be published in <i>Lecture Notes in Bioinformatics</i> (LNBI) post-proceedings. 
              Selected submissions will be invited to special issues of the <i>Journal of Computational Biology</i> (JCB) 
              or <i>Transactions on Computational Biology and Bioinformatics</i> (TCBB) dedicated to the conference.
            </p>

          </Container>

          <Container sx={{ mt: 4 }} textAlign="right">
            <p style={{ fontSize: "16px", textAlign: "right" }}>
              <b>Papers submission deadline:</b> 
              <br />
                <p style={oldDate}>October 7, 2023</p>
                <p style={newDate}>October 21, 2023</p>
              <b>Notification of acceptance:</b> 
              <br />
                <p style={oldDate}>November 1, 2023</p>
                <p style={newDate}>November 8, 2023</p>
{/*               
              <b>Final paper deadline:</b> 
              <br />
                <p style={oldDate}>November 8, 2023</p>
                <p style={newDate}>November 15, 2023</p> 
*/}
              <b>Early registration deadline:</b> 
              <br />
                <p style={oldDate}>November 8, 2023</p>
                <p style={newDate}>November 15, 2023</p>
            </p>
            <br />
            <div style={{ borderTop: "5px dotted #c5c5c5", margin: "10rem 0 0 0;" }} />
            <br />
            <p style={{ fontSize: "16px", textAlign: "right" }}>
              <b>Previous ICCABS conferences:</b><br />

              <ICCABSLink year="2021" ieee={false} />
              <ICCABSLink year="2020" ieee={false} />
              <ICCABSLink year="2019" ieee={false} />
              <ICCABSLink year="2018" ieee={true} />
              <ICCABSLink year="2017" ieee={true} />
              <ICCABSLink year="2016" ieee={true} />
              <ICCABSLink year="2015" ieee={true} />
              <ICCABSLink year="2014" ieee={true} />
              <ICCABSLink year="2013" ieee={true} />
              <ICCABSLink year="2012" ieee={true} />
              <ICCABSLink year="2011" ieee={true} />
              

            </p>
          </Container>
        </div>
      </Card>
      
    </>
  );
}




export default Presentation;
